import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import { TextRegular } from "../../../base/BaseText";

import PaymentIcon from "../../../images/icon-payment-method.svg";
import { showPaymentOption, submitPaymentDetailsNew } from "../state/operators/paymentOperator";
import { CLIENT } from "../../../helpers/constants";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

import '@backoffice/fast-payments-client-js-sdk/microui/vanilla';
// import { BillingGatewayRegion, Environment, Locale, } from '@backoffice/fast-payments-client-js-sdk/microui';

import generateUniqueId from "../../../helpers/generateUniqueId";

const SubmitCardDetailsNew = () => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const t = useTranslate();
  const dispatch = useDispatch();

  const fee = useSelector(
    (state) => state.claim.payment.serviceFee.TotalAmount
  );
  const discountedfee = useSelector(
    (state) => state.claim.payment.serviceFee.TotalAmountWithDiscount
  );
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;

  const goBack = () => {
    // setDisabled(true);
    dispatch(showPaymentOption(t("GoBackButton")));
  };

  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  const PCIToken = useSelector((state) => state.claim?.payment?.pciToken?.SecurityToken?.PCIToken);
  const EnrolledAddress = useSelector((state) => state.claim?.deviceConfirmation?.selectedAgreement?.EnrolledAddress)
  const EmailAddress = useSelector((state) => state.validation.agreement[0]?.EmailAddress || 
  state.validation.agreement?.EmailAddress ||
  state.validation.verification?.EmailAddress);

  const [isPaymentSuccess, setisPaymentSuccess] = useState(false);
  const microUiId = generateUniqueId();

  useEffect(() => {
    if (PCIToken) {
      window.MicroUI.initializePaymentUI(microUiId, {
        billingServiceConfig: {
          // environment: process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD" ? Environment.PROD : Environment.QA,
          // region: BillingGatewayRegion.APAC,
          environment: process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD" ? "PRODUCTION" : "QA",
          region: "APAC",
          applicationId: isSingtel ? "singtel" : "celcom",
          billingProgramId: isSingtel ? "SINGTEL_BT-HORIZON-SCR-WEB-APAC" : "CELCOM_BT-HORIZON-SCR-WEB-APAC",
        },
        applicationSession: PCIToken,
        billingInformation: {
          address: {
            address1: EnrolledAddress?.Address1,
            address2: EnrolledAddress?.Address2,
            city: EnrolledAddress?.City,
            stateOrProvince: EnrolledAddress?.StateProvinceCode,
            postalCode: EnrolledAddress?.PostalCode || "819663",
          },
          contact: {
            email: EmailAddress
          }
        },
        onPaymentSuccess: (response) => {
          console.log('Success Transaction: ', response);
          setisPaymentSuccess(true);
          dispatch(
            submitPaymentDetailsNew(
              response.paymentMethod,
              `${response?.billingInformation?.contact?.firstName} ${response?.billingInformation?.contact?.lastName}`,
              `${response?.additionalData?.cardBin}${response?.additionalData?.cardLastFourDigits}`,
              response.additionalData?.cardExpirationDate?.split("/")[0],
              response.additionalData?.cardExpirationDate?.split("/")[1],
              '',
              response.additionalData?.cardBrand,
              response.billingInformation?.address?.postalCode,
              "Continue"
            )
          );
        },
        onPaymentFailure: (response) => {
          console.log('Error Transaction: ', response);
        },
        // locale: isSingtel ? Locale.enSG : Locale.msMY,
        locale: isSingtel ? 'en-SG' : 'ms-MY',
        styleOverride: {
          fontFamily: isSingtel ? 'DINPro-Regular, sans-serif' : '',
          continueButtonColor: isSingtel ? '#8223D2' : '#0064dc',
          continueButtonBorderRadius: '100px',
          continueButtonHoverColor: isSingtel ? '#8223D2' : '#0064dc',
        },

      })
    }
  }, [PCIToken])

  return (
    <>
      <BaseCard className={`pb-12 ${isPaymentSuccess ? "pointer-events-none" : ""}`} style={{ maxWidth: "50rem"}}>
        <BaseIcon icon={PaymentIcon} name="Payment Method" center />
        <BaseLabel className="my-5">
          <Translate i18nKey={isSingtelStarter 
            ? "Payment.TitleBatteryReplacement" 
            : caseType === INCIDENT_BATTERY_REPLACEMENT ? "Payment.TitleBatteryReplacement" : "Payment.Title"}>
            <TextRegular>
              Repair fee of ${{ fee: discountedfee || fee }} (inclusive of GST) is applicable.
            </TextRegular>
          </Translate>
        </BaseLabel>

        <div id="paymentUI"><div id={microUiId} /></div>

      </BaseCard>
    
    {/*<BaseCard>
      <BaseIcon icon={PaymentIcon} name="Payment Method" center />
      <BaseLabel className="my-2">
        <Translate i18nKey={isSingtelStarter ? "Payment.TitleBatteryReplacement" : "Payment.Title"}>
          <TextRegular>
            Repair fee of ${{ fee: discountedfee || fee }} (inclusive of GST) is applicable.
          </TextRegular>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-2 mb-0">
        <Translate i18nKey="Payment.Label">
          <TextRegular>
            Please provide your
            <br /> Credit Card/Debit Card details below
          </TextRegular>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-0 mt-4 pl-2 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="Payment.Input.Name" />
        </span>
      </BaseLabel>
      <BaseInput
        className="mb-3"
        placeholder={t("Payment.Placeholder.Name")}
        value={name}
        onChange={handleNameChange}
        disabled={disabled}
        medium
        errorText={nameError}
      />
      <BaseLabel className="my-0 mt-4 pl-2 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="Payment.Input.CardNumber" />
        </span>
      </BaseLabel>
      <BaseInput
        className="mb-3"
        placeholder={t("Payment.Placeholder.CardNumber")}
        value={cardNumber}
        onChange={(val) => handleCardNumberChange(val.trim())}
        disabled={disabled}
        maxLength={16}
        medium
        errorText={cardNumberError}
        Suffix={handleCardType()}
      />
      <BaseLabel className="my-0 mt-4 pl-2 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="Payment.Input.ExpiryDate" />
        </span>
      </BaseLabel>
      <BaseInput
        className="mb-3"
        placeholder={t("Payment.Placeholder.ExpiryDate")}
        value={expiryDate}
        onChange={(val) => handleExpiryDateChange(val.trim())}
        disabled={disabled}
        maxLength={7}
        errorText={expiryDateError}
        medium
      />
      <BaseLabel className="my-0 mt-4 pl-2 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="Payment.Input.Cvv" />
        </span>
      </BaseLabel>
      <BaseInput
        type="password"
        placeholder={t("Payment.Placeholder.Cvv")}
        value={cvv}
        onChange={(val) => handleCvvChange(val.trim())}
        disabled={disabled}
        maxLength={4}
        medium
        Suffix={HelpButton}
        errorText={cvvError}
      />
      <BaseButtonGroup className="mt-6">
        <BaseButton
          text={t("ClearButton")}
          onClick={handleClearClick}
          disabled={disabled}
        />
        <BaseButton
          className="text-1.3"
          text={t("Payment.SubmitButton")}
          onClick={handleSubmitClick}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
      {!isSingtel && (
        <div
          className={` ${
            disabled
              ? "cursor-not-allowed items-center justify-around text-center m-4 "
              : "cursor-pointer items-center justify-around text-center m-4 "
          }`}
          disabled={disabled}
          onClick={!disabled && goBack}
        >
          <a
            className="text-srgreen-secondary text-xl"
          >
            {t("GoBackButton")}
          </a>
        </div>
      )}
    </BaseCard>*/}
    </>
  );
};

export default SubmitCardDetailsNew;
